import React from "react";

export default function Loading() {
  return (
      <div className="loading" style={{textAlign:"center"}}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Laden...</span>
    </div>
    </div>
  );
}
