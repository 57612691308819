import React from "react";
import ProductOptie from "./ProductOptie";

// TODO: Minimaal aantal werkt nog niet
// TODO: Prijs

export default class ProductOpties extends React.Component {
  constructor() {
    super();
    this.state = {
      options: [],
      quantity: 1
    };
    this.handleChange = this.handleChange.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
  }

  handleChange(optionsID, e) {
    const val = e.target.value;
    this.setState(prevState => {
      let options = prevState.options;

      const search = options.findIndex(x => x.id === optionsID);

      const optionObj = {
        id: optionsID,
        valID: val - 1
      };

      search > -1 ? (options[search] = optionObj) : options.push(optionObj);

      return { options: options };
    });
  }

  setQuantity(e) {
    let val = e.target.value;
    if (val < 1) {
      val = 1;
    }
    this.setState({
      quantity: val
    });
  }

  render() {
    let options = [];
    if (this.props.options) {
      options = this.props.options.map((option, index) => {
        return (
          <ProductOptie
            key={index}
            id={index}
            title={option.titel}
            type={option.type}
            choices={option.keuzes}
            handleChange={this.handleChange}
          />
        );
      });
    }
    return (
      <div
        className="options-wrapper"
        style={{ marginTop: this.props.position }}
      >
        {options.length ? <div className="options">{options}</div> : null}

        <div className="quantity">
          <input
            type="number"
            placeholder="Aantal"
            value={this.state.quantity}
            onChange={this.setQuantity}
            min="1"
          ></input>
          <button
            
            onClick={() => {
              this.props.addToCart(
                this.props.data.id,
                this.state.options,
                this.state.quantity
              );

              this.props.animateAddToCart()

                this.setState({
                  quantity: 1
                });

            }}
          >
            Toevoegen
          </button>
          {/* TODO: + en - */}
          {/* TODO: Prijsberekening/ */}
        </div>
      </div>
    );
  }
}
