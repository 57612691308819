import React from 'react'

class Header extends React.Component {

    constructor() {
        super()
        this.state = {
            logo: "",
            achtergrond: "",
            slogan: ""
        }
    }

    componentDidMount() {
        fetch('https://slagbroek.media-kanjers.nl/wp-json/acf/v3/options/acf-options-bestelsysteem')
        .then(response => response.json())
        .then(options => {
        this.setState({
            logo: options.acf.logo.url,
            achtergrond: options.acf.achtergrond.sizes.large,
            slogan: options.acf.slogan
        }); 
    })

    }
        render(){
            return (
                <header className="header" style={{backgroundImage: `url(${this.state.achtergrond})`}}>
                <div className="slogan">{this.state.slogan}</div>
                    <div className="logo">
                        <img src={this.state.logo} alt=""/>
                    </div>
                </header>
            )
        }
    
}

export default Header;