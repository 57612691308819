import React from "react";
import Product from "../product/Product";

export default function ChildCategorie(props) {
  const productArray = props.products.map(product => {
    return (
      <Product
        key={product.id}
        id={product.id}
        activeID={props.activeID}
        data={product}
        toggle={props.toggle}
        addToCart={props.addToCart}
      />
    );
  });
  return (
    <div className="childCategorie">
      <h5 className="title">{props.name}</h5>
      {productArray}
    </div>
  );
}
