import React, { Component } from "react";
import ShoppingCartContents from "./ShoppingCartContents";
import axios from 'axios';

//TODO: Producten tonen
//TODO: Geen bestelling kunnen plaatsen als mand leeg is
//TODO: Bestellen

export default class ShoppingCart extends Component {
                 constructor() {
                   super();
                   this.state = {
                     isActive: false
                   };
                   this.handleClick = this.handleClick.bind(this);
                   this.sendOrder = this.sendOrder.bind(this);
                 }

                 handleClick(prevState) {
                   this.setState(prevState => {
                     return {
                       isActive: !prevState.isActive
                     };
                   });
                 }


                //  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3d3LnNsYWdlcmlqYnJvZWtodWlzLm5sIiwiaWF0IjoxNTcxOTA3MDcwLCJuYmYiOjE1NzE5MDcwNzAsImV4cCI6MTU3MjUxMTg3MCwiZGF0YSI6eyJ1c2VyIjp7ImlkIjoiMSJ9fX0.sYdsXcng6uJHpkaZm1bsabMti5CNz7tcIyMoImKe3jA
                 sendOrder() {
                  // Cart to ACF object
                  let producten = {};
                  this.props.cart.forEach((element, i) => {
                    producten[i] = element;
                  });

                
                  axios({
                    method: 'post',
                    url: 'https://slagbroek.media-kanjers.nl/wp-json/wp/v2/bs_bestelling',
                    'headers': {
                      "Authorization": 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9zbGFnYnJvZWsubWVkaWEta2FuamVycy5ubCIsImlhdCI6MTU3MzA0ODE5NCwibmJmIjoxNTczMDQ4MTk0LCJleHAiOjE1NzM2NTI5OTQsImRhdGEiOnsidXNlciI6eyJpZCI6IjIifX19._lIfzZUW4rXot2bbsOo8zNwCDHFcKjAfEyOUKX8BvN8'
                    },
                    data: 
                      {
                        "title": "Test4",
                        "fields" : {
                          "producten" : producten
                          // TODO: Opties meegeven
                        }
                      }
                    
                  }).then(function(response) {
                    if (response.status === 201) {
                      // TODO: Bestelling bevestiging 
                    } else {
                      // TODO: Error bevestigen
                    }
                  })
                  .catch(function(error) {
                    // TODO: Error bevestigen
                  });
                } 

                 render() {
                   return (
                     <div
                       className={
                         this.state.isActive
                           ? "shoppingcart-wrapper active"
                           : "shoppingcart-wrapper"
                       }
                     >
                       <div onClick={this.handleClick} className="cartToggle">
                         Cart
                       </div>
                       <div className="cart">
                         <h5>Winkelmand</h5>
                         <div className="products">
                           <div className="product">
                             <ShoppingCartContents
                               cart={this.props.cart}
                               products={this.props.products}
                             />
                           </div>
                         </div>

                       {/* TODO: Adres gegevens */}

                         <button
                           onClick={this.sendOrder}
                           className="btn btn-primary"
                         >
                           Bestellen
                         </button>
                       </div>
                     </div>
                   );
                 }
               }
