import React, { Component } from 'react'
import Categorie from "../../components/categorie/Categorie";

export default class Categories extends Component {

    constructor(){
        super()
        this.state = {
            categories: [],
        }
    }

    componentDidMount(){
        fetch(
            "https://slagbroek.media-kanjers.nl/wp-json/wp/v2/bs_categorie?per_page=100"
          )
            .then(response => response.json())
            .then(data => {
              let categories = [];
              data.forEach(cat => {
                if (cat.parent === 0) {
                  let id = cat.id;
                  let name = cat.name;
                  let description = cat.description
                  let image = cat.acf.afbeelding.sizes.medium;
                  let childs = [];
  
                  data.forEach(child => {
                    if (child.parent === id) {
                      childs.push(child);
                    }
                  });
  
                  let productsByCat = this.props.products.filter(product =>
                    product.bs_categorie.includes(id)
                  );
                    
                  console.log(this.props.products);
                  categories.push(
                    <Categorie
                      key={id}
                      name={name}
                      description={description}
                      image={image}
                      childs={childs}
                      products={productsByCat}
                      addToCart={this.props.addToCart}
                    />
                  );
                }
              });
  
              this.setState({
                categories: categories,
                isLoaded: true
              });
            });
    }

    render() {
        return (
            <div className="categories">
                {this.state.categories}
            </div>
        )
    }
}
