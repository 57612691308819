import React from "react";
import Header from "./components/layout/Header";
import ShoppingCart from "./components/cart/ShoppingCart";
import Loading from "./components/layout/Loading";
import Categories from "./components/categorie/Categories";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      productsLoaded: false,
      products: [],
      shoppingCart: []
    };
    this.addToCart = this.addToCart.bind(this);


    
  }

  addToCart(id, options, quantity) {
    // const product = this.state.products.find(p => p.id === id)
    this.setState(prevState => {
      let shoppingCart = prevState.shoppingCart;
      const search = shoppingCart.findIndex(x => x.id === id);

      const cartObj = {
        id: id,
        options: options,
        quantity: parseInt(quantity)
      };

      if (search > -1) {
        let oldQty = shoppingCart[search].quantity;

        cartObj.quantity += oldQty;

        shoppingCart[search] = cartObj;
      } else {
        shoppingCart.push(cartObj);
      }

      return { shoppingCart: shoppingCart };
    });
  }

  componentDidMount() {
    fetch(
      "https://slagbroek.media-kanjers.nl/wp-json/wp/v2/bs_product?per_page=100"
    )
      .then(response => response.json())
      .then(products => {
        this.setState({
          products: products,
          productsLoaded: true,
        });

        // fetch(
        //   "https://slagbroek.media-kanjers.nl/wp-json/wp/v2/bs_categorie?per_page=100"
        // )
        //   .then(response => response.json())
        //   .then(data => {
        //     let categories = [];
        //     data.forEach(cat => {
        //       if (cat.parent === 0) {
        //         let id = cat.id;
        //         let name = cat.name;
        //         let image = cat.acf.afbeelding.sizes.medium;
        //         let childs = [];

        //         data.forEach(child => {
        //           if (child.parent === id) {
        //             childs.push(child);
        //           }
        //         });

        //         let productsByCat = products.filter(product =>
        //           product.bs_categorie.includes(id)
        //         );

        //         categories.push(
        //           <Categorie
        //             key={id}
        //             name={name}
        //             image={image}
        //             childs={childs}
        //             products={productsByCat}
        //             addToCart={this.addToCart}
        //           />
        //         );
        //       }
        //     });

        //     this.setState({
        //       categories: categories,
        //       isLoaded: true
        //     });
        //   });
      });
  }
  render() {
    return (
      <>
      <Header />
      <div className="container">
        
         {this.state.productsLoaded ? <Categories addToCart={this.addToCart} products={this.state.products}/> : <Loading />}
        
      </div>
        <ShoppingCart cart={this.state.shoppingCart} products={this.state.products}/>
        </>
    );
  }
}

export default App;
