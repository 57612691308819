import React from "react";
import leftArrow from "./images/left-arrow.png"

function CategorieHeader(props) {
    return (
      <div className="categorie--header" onClick={props.handleClick}>
        <img className="back" src={leftArrow} alt="Terug"></img>
        <div className="image" style={{backgroundImage: `url(${props.image})`}}>
          
        </div>
        <div className="name">{props.name}</div>
        <div className="desc">{props.description}</div>
      </div>
    );
}

export default CategorieHeader
