import React from 'react'

export default function ShoppingCartContents(props) {
    
    
   const cartContents = props.cart.map(p => {
        
        const ID = p.id;
        const qty = p.quantity;
        // const options = p.options;
        const product = props.products.find(f => f.id === ID)
        // console.log(product);
        const price = product.acf.basisprijs;
        const totalPrice = qty * price;
        return <li key={ID}><span>{qty}x</span> <span>{product.title.rendered}</span> <span>{totalPrice}</span></li>
   })


    
    
    
    return (
        <ul>
        {cartContents}
        </ul>
    )
}
