import React from "react";

export default function ProductOptie(props) {
  let selectField;
  if (props.type.toLowerCase() === "select") {
    const options = props.choices.map((o, index) => {
      return (
        <option key={index} value={index}>
          {index ? o.titel : props.title}
        </option>
      );
    });

    selectField = (
      <select onChange={e => props.handleChange(props.id, e)}>
        {options}
      </select>
    );
  }

  return (
    <div className="option">
      {selectField}
    </div>
  );
}
