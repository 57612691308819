import React from "react";
import ChildCategorie from "./ChildCategorie";

export default class CategorieContent extends React.Component {
  constructor() {
    super();
    this.state = {
      activeProductID: ""
    };
    this.toggleOptions = this.toggleOptions.bind(this);
  }

  toggleOptions(id, isOpen, ref, e) {
    if (isOpen) {
      id = 0;
    } else {
      // setTimeout(() => {
      //   ref.current.scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'start',
      //   });
      // }, 500);
    }
    this.setState({
      activeProductID: id
    });
  }

  render() {
    let childs = [];
    this.props.childs.forEach(e => {
      let products = this.props.products.filter(product =>
        product.bs_categorie.includes(e.id)
      );
      if (products.length) {
        childs.push(
          <ChildCategorie
            key={e.id}
            activeID={this.state.activeProductID}
            name={e.name}
            products={products}
            toggle={this.toggleOptions}
            addToCart={this.props.addToCart}
          />
        );
      }
    });
    return (
      <div className="categorie--content" style={{ height: this.props.height }}>
        <div className="container ">{childs}</div>
      </div>
    );
  }
}
