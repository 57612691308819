import React, { Component } from "react";
import euro from "./images/euro.svg"

export default class ProductContent extends Component {
  constructor() {
    super();
    this.state = {
      image: ""
    };
  }

  convertToPrice(price) {
    return price.replace(".", ",");
  }

  componentDidMount() {
    if (this.props.data.featured_media) {
      fetch(
        `https://slagbroek.media-kanjers.nl/wp-json/wp/v2/media/${this.props.data.featured_media}`
      )
        .then(image => image.json())
        .then(image => {
          this.setState({
            image: image.media_details.sizes.full.source_url
          });
        });
    }
  }
  render() {
    return (
      <div id="productcontent" className="content" onClick={(e) => this.props.toggle(this.props.data.id, this.props.isOpen, this.props.productRef, e)}>
        <div id="productimage" className="image">
          <img src={this.state.image} alt="" />
        </div>
<div id="productinfo" className="info">
          <h6 className="title">{this.props.data.title.rendered}</h6>
          <p
            className="desc"
            dangerouslySetInnerHTML={{ __html: this.props.data.content.rendered }}
          ></p>
          <div className="price">
          <img className="eur" src={euro} alt="euro"></img>{this.convertToPrice(this.props.data.acf.basisprijs)}
          </div>
          <div className="toggle">{this.props.isOpen ? "-" : "+"}</div>
</div>
      </div>
    );
  }
}
