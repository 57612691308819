import React from "react";
import ProductOpties from "./ProductOpties";
import ProductContent from "./ProductContent";

class Product extends React.Component {
  constructor() {
    super();
    this.state = {
      optionsHeight: 0
    };
    this.animateAddToCart = this.animateAddToCart.bind(this)
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.setState({
      optionsHeight: this.ref.current.children[1].offsetHeight
    });
  }

  animateAddToCart() {
    const image = this.ref.current.children['productcontent'].children['productimage'];
    const clone = image.cloneNode(true);
    const target = document.getElementsByClassName('cartToggle')[0].getBoundingClientRect();

    clone.style.top = image.getBoundingClientRect().top + "px";
    clone.style.left = image.getBoundingClientRect().left + "px";
    clone.style.width = image.getBoundingClientRect().width + "px";

    clone.classList.add("clone");
    document.body.appendChild(clone)

    setTimeout(() => { 
      clone.style.top = target.top + "px";
      clone.style.left = target.left + "px";
      clone.style.width = target.width + "px";
    }, 50);

    setTimeout(() => { 
      clone.style.transform = "scale(0)";
    }, 750);

    setTimeout(() => { 
      clone.remove();
    }, 1500);

    


  }

  render() {
    const height = this.state.optionsHeight;
    let isOpen = false;
    let position = height * -1;
    if (this.props.activeID === this.props.id) {
      isOpen = true;
      position = 0;
    }

    return (
      <div className="product" ref={this.ref}>
        <ProductContent
          data={this.props.data}
          isOpen={isOpen}
          toggle={this.props.toggle}
          productRef={this.ref}
        />
        <ProductOpties
          data={this.props.data}
          options={this.props.data.acf.product_opties}
          position={position}
          addToCart={this.props.addToCart}
          animateAddToCart={this.animateAddToCart}
        />
      </div>
    );
  }
}

export default Product;
