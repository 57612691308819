import React, { Component } from "react";
import CategorieHeader from "./CategorieHeader";
import CategorieContent from "./CategorieContent";

class Categorie extends Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.state = {
      isActive: false,
      position: {},
      contentHeight:0
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    let pos = this.ref.current.getBoundingClientRect();
    let newPos;
    let contentHeight = 0;
    if (!this.state.isActive) {
      newPos = {
        zIndex: 10,
        top: pos.top * -1,
        left: pos.left * -1,
        width: window.innerWidth,
        height: window.innerHeight
      };
      contentHeight = window.innerHeight - this.ref.current.children[0].offsetHeight;
      document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
    } else {
      newPos = {
        position: "",
      };
      document.getElementsByTagName("BODY")[0].style.overflow = "auto";
    }

    this.setState(prevState => {
      return {
        position: newPos,
        contentHeight: contentHeight,
        isActive: !prevState.isActive
      };
    });
  }

  render() {
    let classes = this.state.isActive ? "categorie active" : "categorie";
    return (
      <div className={classes} ref={this.ref} style={this.state.position}>
        <CategorieHeader
          name={this.props.name}
          description={this.props.description}
          image={this.props.image}
          handleClick={this.handleClick}
        />
        <CategorieContent
          childs={this.props.childs}
          products={this.props.products}
          height={this.state.contentHeight}
          addToCart={this.props.addToCart}
        />
      </div>
    );
  }
}

export default Categorie;
